import React from "react"
import SingleMessage from "../../components/sections/SingleMessage"
import Layout from "../../components/common/Layout"
import { navigate } from "gatsby"

const Success = () => {
  return (
    <Layout>
      <SingleMessage
        message="An expert will contact you soon."
        btnLabel="See portfolio"
        onClick={() => navigate("http://www.thekitchenguy.pro/portfolio")}
      />
    </Layout>
  )
}

export function Head() {
  return <title>The Kitchen Guy</title>
}

export default Success
